html,
body {
	height: 100%;
}

#root {
	min-height: 100%;
	height: auto;
	display: flex;
}

// main{
//     padding-top: 90px;
//     flex: 1;
//     padding: 90px 30px 20px;
// }

h2 {
	@media (max-width: 768px) {
		font-size: 27px;
		line-height: 40px;
	}
}
.no-scroll {
	overflow: scroll !important;
}

a {
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}
